<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { ChatIcon } from '@cfgtech/icons'
import type { Author } from '~/api/types/generated/Author'
import type { Media } from '~/api/types/generated/Media'
import type { BlogCategoriesEnum } from '~/composables'

const props = withDefaults(
  defineProps<{
    title: string
    description: string
    createdAt?: Date
    slug?: string
    url?: string
    categories?: BlogCategoriesEnum[]
    poster?: Media
    author?: Author
    titleLevel: 'h2' | 'h3'
    desktopViewBreakpoint?: 'sm' | 'md' | 'lg' | 'xl' | false
    variant?: 'normal' | 'small'
    isCommentButtonVisible?: boolean
  }>(),
  {
    desktopViewBreakpoint: 'md',
    categories: undefined,
    poster: undefined,
    author: undefined,
    createdAt: undefined,
    isCommentButtonVisible: true,
    variant: 'normal',
    slug: '',
    url: '',
  },
)

const emit = defineEmits<{
  dataLayerUpdate: [boolean]
}>()

const router = useRouter()

const viewBreakpointsMap = {
  sm: 'sm:grid-cols-[minmax(100px,1fr)_2fr]',
  md: 'md:grid-cols-[minmax(100px,1fr)_2fr]',
  lg: 'lg:grid-cols-[minmax(100px,1fr)_2fr]',
  xl: 'xl:grid-cols-[minmax(100px,1fr)_2fr]',
}

const breakpointClass = computed(() => {
  if (!props.desktopViewBreakpoint) {
    return ''
  }

  return viewBreakpointsMap[props.desktopViewBreakpoint] || viewBreakpointsMap.md
})

const linkRoute = computed(() => {
  if (props.slug) {
    return `/blog/${props.slug}`
  }

  return props.url
})

function handleCommentPost(isOpenedInNewWindwow: boolean) {
  emit('dataLayerUpdate', true)

  const route = `${linkRoute.value}#comments`

  if (isOpenedInNewWindwow) {
    const resolvedRoute = router.resolve(route)
    window.open(resolvedRoute.href, '_blank')
    return
  }

  router.push(route)
}
</script>

<template>
  <NuxtLink
    class="focusable group cursor-pointer gap-4 rounded-xl border border-grey-stroke bg-white p-5 transition-transform"
    :class="{
      [`${breakpointClass} grid`]: !!props.desktopViewBreakpoint,
      'flex flex-col': !props.desktopViewBreakpoint,
    }"
    :to="linkRoute"
  >
    <span class="relative block aspect-[6/4] rounded-xl bg-brand/10">
      <NuxtImg
        v-if="poster?.attributes?.url"
        :alt="poster?.attributes?.alternativeText || title"
        class="absolute top-0 size-full rounded-lg object-cover object-center"
        format="webp"
        height="192"
        loading="lazy"
        placeholder
        quality="70"
        :src="poster?.attributes.url"
        width="288"
      />
    </span>

    <article class="flex flex-1 flex-col justify-between gap-y-4">
      <div>
        <header>
          <CfgTypography
            class="grow text-brand underline hover:no-underline"
            :size="variant === 'normal' ? CfgTypographySizes.h3 : CfgTypographySizes.subtitle"
            :tag-name="titleLevel"
          >
            {{ title }}
          </CfgTypography>
        </header>

        <div class="mt-3">
          <CfgTypography
            class="line-clamp-4 break-all text-black"
            :size="CfgTypographySizes.md"
          >
            <span v-html="description" />
          </CfgTypography>
        </div>
      </div>

      <footer>
        <BlogPostAuthor
          v-if="author"
          class="mb-5"
          :created-at="createdAt"
          :img-url="author?.attributes?.photo?.data?.attributes?.url"
          :name="author?.attributes?.name"
          :size="CfgTypographySizes.sm"
        />

        <div class="flex">
          <ul v-if="categories?.length" class="width-[70%] flex flex-wrap gap-x-3 gap-y-4">
            <li v-for="category in categories" :key="category">
              <BlogCategoriesChip
                :category="category"
                :is-selected="false"
                is-small
              />
            </li>
          </ul>

          <span
            v-if="isCommentButtonVisible"
            class="ml-auto flex cursor-pointer flex-row items-center gap-x-2"
            @click.stop.prevent="handleCommentPost(false)"
            @click.stop.prevent.middle="handleCommentPost(true)"
          >
            <ChatIcon class="text-[20px] text-brand" />

            <CfgTypography
              class="!font-highlighted text-brand hover:underline"
              :size="CfgTypographySizes.sm"
            >
              {{ $t('blog.posts.comment') }}
            </CfgTypography>
          </span>
        </div>
      </footer>
    </article>
  </NuxtLink>
</template>
