<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { BlogCategoriesEnum } from '@/composables/blog/types'

const props = withDefaults(defineProps<{
  category?: BlogCategoriesEnum
  as?: string
  isSelected?: boolean
  isInterective?: boolean
  isSmall?: boolean
}>(), { as: 'span', category: undefined })

const { t } = useI18n()

const categoriesToTranslatesMapper = {
  [BlogCategoriesEnum.BondReviews]: t('blog.categories.bondReviews'),
  [BlogCategoriesEnum.MarketNews]: t('blog.categories.marketNews'),
  [BlogCategoriesEnum.BondsStatistics]: t('blog.categories.bondsStatistics'),
  [BlogCategoriesEnum.Comments]: t('blog.categories.comments'),
}

const label = computedEager(() => {
  if (props.category) {
    return categoriesToTranslatesMapper[props.category]
  }

  return t('blog.categories.all')
})
</script>

<template>
  <Component
    :is="as"
    class="relative block overflow-hidden rounded-full border leading-none ring-offset-4 transition duration-200 focus-within:ring focus-within:ring-brand/30"
    :class="{
      'border-brand bg-brand text-white': isSelected,
      'border-grey-stroke bg-white': !isSelected && isInterective,
      'border-transparent bg-grey-50': !isSelected && !isInterective,
      'hover:-translate-y-1': isInterective,
    }"
  >
    <CfgTypography
      class="block px-[1em] py-[0.75em]"
      :size="!isSmall ? CfgTypographySizes.sm : CfgTypographySizes.xs"
      tag-name="span"
    >
      <slot :label="label">
        <!-- Chip content -->
        {{ label }}
      </slot>
    </CfgTypography>
  </Component>
</template>
